@import url(https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap);
* {
  box-sizing: border-box;
}

body {
  padding-top: 4rem;
  margin: 0px;
  color: #333;
  --type-first: Helvetica, Arial, sans-serif;
  --type-second: "Spectral", Georgia;
  font-family: var(--type-first);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

.container {
  /* 800px */
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0 auto;
}

.mainContainer {
  margin-top: 2rem;
}

.title {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 3rem;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
}

.title::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: #fb1;
  position: absolute;
  bottom: 5px;
  left: -5px;
  border-radius: 0.2rem;
  z-index: -1;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: none;
    transform: initial;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh + 5rem);
}

.AppBody {
  flex: 1 1;
}

.Header_header__2fIN1 {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background: white;
  top: 0px;
}

.Header_nav__2n7rG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.Header_logo__21Rn4 {
  padding: 0.5rem 0;
}

.Header_login__HlIPV {
  color: #333;
  display: flex;
  align-items: center;
}

.Header_login__HlIPV::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 17px;
  background: url(/static/media/usuario.6d65a298.svg) no-repeat center center;
  margin-left: 0.5rem;
  position: relative;
  top: -1px;
}

.Footer_footer__320pW {
  background: #fb1;
  padding: 1rem;
  height: 5rem;
  text-align: center;
  color: #764701;
}

.Footer_footer__320pW p {
  margin-top: 0.5rem;
}

.FeedModal_modal__eZWG1 {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 1000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
}

@media (max-width: 40rem) {
  .FeedModal_modal__eZWG1 {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}

.Loading_wrapper__9kdUc {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.Loading_loading__1WKA8 {
  margin: auto;
  width: 80px;
  height: 80px;
  display: flex;
  padding-left: 5px;
  background: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.PhotoContent_photo__1Jnhn {
  margin: auto;
  height: 36rem;
  border-radius: 0.2rem;
  background: white;
  display: grid;
  grid-template-columns: 36rem 20rem;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  opacity: 0;
  transform: scale(0.8);
  animation: PhotoContent_scaleUp__29KND 0.3s forwards;
}

.PhotoContent_single__8BlM0.PhotoContent_photo__1Jnhn {
  grid-template-columns: 1fr;
  height: auto;
}

@keyframes PhotoContent_scaleUp__29KND {
  to {
    opacity: 1;
    opacity: initial;
    transform: none;
    transform: initial;
  }
}

.PhotoContent_details__2s43a {
  padding: 2rem 2rem 0 2rem;
}

.PhotoContent_single__8BlM0 .PhotoContent_details__2s43a {
  padding: 1rem 0px 0px 0px;
}

.PhotoContent_img__dyYGZ {
  grid-row: 1/4;
}

.PhotoContent_single__8BlM0 .PhotoContent_img__dyYGZ {
  grid-row: 1;
  border-radius: 0.4rem;
  overflow: hidden;
}

@media (max-width: 64rem) {
  .PhotoContent_photo__1Jnhn {
    height: auto;
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
    grid-template-columns: minmax(20rem, 40rem);
  }
  .PhotoContent_img__dyYGZ {
    grid-row: 1;
  }
}

.PhotoContent_author__1LlJ6 {
  opacity: 0.5;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PhotoContent_author__1LlJ6 a:hover {
  text-decoration: underline;
}

.PhotoContent_visualizacoes__3dSIl::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 0.5rem;
  background: url(/static/media/visualizacao-black.ee94fd3d.svg);
}

.PhotoContent_attributes__1Ha5Q {
  display: flex;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.PhotoContent_attributes__1Ha5Q li {
  margin-right: 2rem;
}

.PhotoContent_attributes__1Ha5Q li::before {
  content: "";
  display: inline-block;
  height: 20px;
  margin-right: 0.5rem;
  position: relative;
  top: 3px;
  width: 2px;
  background: #333;
  margin-top: 5px;
}

.PhotoCommentsForm_form__1ObVk {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: stretch;
  margin: 1rem;
}

.PhotoCommentsForm_single__1itCA.PhotoCommentsForm_form__1ObVk {
  margin: 1rem 0;
}

.PhotoCommentsForm_textarea__30xsk {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-family: var(--type-first);
  resize: none;
  border: 1px solid #eee;
  padding: 0.5rem;
  border-radius: 0.2rem;
  background: #eee;
  transition: 0.2s;
}

.PhotoCommentsForm_textarea__30xsk:focus,
.PhotoCommentsForm_textarea__30xsk:hover {
  outline: none;
  border-color: #fb1;
  background: white;
  box-shadow: 0 0 0 3px #fea;
}

.PhotoCommentsForm_button__1PvYg {
  border: none;
  cursor: pointer;
  color: #333;
  background: transparent;
  font-size: 1rem;
  padding: 0 1rem;
  overflow: hidden;
}

.PhotoCommentsForm_button__1PvYg:focus,
.PhotoCommentsForm_button__1PvYg:hover {
  outline: none;
}

.PhotoCommentsForm_button__1PvYg:focus svg path,
.PhotoCommentsForm_button__1PvYg:hover svg path {
  fill: #fea;
  stroke: #fb1;
}

.PhotoCommentsForm_button__1PvYg:focus svg g,
.PhotoCommentsForm_button__1PvYg:hover svg g {
  animation: PhotoCommentsForm_latir__1V0ei 0.6s infinite;
}

@keyframes PhotoCommentsForm_latir__1V0ei {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.PhotoComments_comments__2hQIL {
  overflow-y: auto;
  word-break: break-word;
  padding: 0 2rem;
}

.PhotoComments_single__1adcM.PhotoComments_comments__2hQIL {
  padding: 0px;
}

.PhotoComments_comments__2hQIL li {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.PhotoDelete_delete__3Qe5r {
  background: #ddd;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  transition: 0.1s;
}

.PhotoDelete_delete__3Qe5r:focus,
.PhotoDelete_delete__3Qe5r:hover {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
}

.Image_wrapper__md0qt {
  display: grid;
}

.Image_img__33hkl {
  display: block;
  max-width: 100%;
  grid-area: 1/1;
  opacity: 0;
  transition: 0.2s;
}

.Image_skeleton__1y084 {
  grid-area: 1/1;
  height: 100%;
  background-image: linear-gradient(90deg, #eee 0px, #fff 50%, #eee 100%);
  background-color: #eee;
  background-size: 200%;
  animation: Image_skeleton__1y084 1.5s infinite linear;
}

@keyframes Image_skeleton__1y084 {
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
}

.FeedPhotosItem_photo__qsMfX:nth-child(2) {
  grid-column: 2 / 4;
  grid-row: span 2;
}

@media (max-width: 40rem) {
  .FeedPhotosItem_photo__qsMfX:nth-child(2) {
    grid-column: initial;
    grid-row: initial;
  }
}

.FeedPhotosItem_photo__qsMfX {
  display: grid;
  border-radius: 0.2rem;
  overflow: hidden;
  cursor: pointer;
}

.FeedPhotosItem_photo__qsMfX > div {
  grid-area: 1/1;
}

.FeedPhotosItem_visualizacao__1-Gmi {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  grid-area: 1/1;
  display: none;
}

.FeedPhotosItem_visualizacao__1-Gmi::before {
  width: 16px;
  height: 10px;
  content: "";
  display: inline-block;
  margin-right: 0.25rem;
  background: url(/static/media/visualizacao.09cd5578.svg) no-repeat;
}

.FeedPhotosItem_photo__qsMfX:hover .FeedPhotosItem_visualizacao__1-Gmi {
  display: flex;
}

.FeedPhotos_feed__1f_1A {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-items: center;
}

@media (max-width: 40rem) {
  .FeedPhotos_feed__1f_1A {
    grid-template-columns: repeat(2, 1fr);
  }
}

.Input_wrapper__1Bj1s {
  margin-bottom: 1rem;
}

.Input_input__1wuvu {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.Input_input__1wuvu:focus,
.Input_input__1wuvu:hover {
  outline: none;
  border-color: #fb1;
  background: white;
  box-shadow: 0 0 0 3px #fea;
}

.Input_label__qTbIS {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.Input_error__SHdbV {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.Button_button__1OHuF {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #fb1;
  color: #764701;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  transition: 0.1s;
}

.Button_button__1OHuF:hover,
.Button_button__1OHuF:focus {
  outline: none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px #fb1;
}

.Button_button__1OHuF:disabled {
  opacity: 0.5;
  cursor: wait;
}

.LoginForm_form___aCZp {
  margin-bottom: 2rem;
}

.LoginForm_lost__1VOH2 {
  display: inline-block;
  color: #666;
  padding: 0.5rem 0;
  line-height: 1;
}

.LoginForm_lost__1VOH2::after {
  content: "";
  height: 2px;
  width: 100%;
  background: currentColor;
  display: block;
}

.LoginForm_register__1Ugg0 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.LoginForm_register__1Ugg0 p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.LoginForm_subtitle__2t_1t {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 2rem;
}

.LoginForm_subtitle__2t_1t::after {
  content: "";
  display: block;
  background: #ddd;
  height: 0.5rem;
  width: 3rem;
  border-radius: 0.2rem;
}

.Login_login__L32O8 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  grid-gap: 2rem;
  gap: 2rem;
}

.Login_login__L32O8::before {
  display: block;
  content: "";
  background: url(/static/media/login.07d71f5b.jpg) no-repeat center center;
  background-size: cover;
}

.Login_forms__2iBAI {
  max-width: 30rem;
  padding: 1rem;
  margin-top: 10vh;
}

@media (max-width: 40rem) {
  .Login_login__L32O8 {
    grid-template-columns: 1fr;
  }
  .Login_login__L32O8::before {
    display: none;
  }
  .Login_forms__2iBAI {
    max-width: 100%;
  }
}

.UserHeaderNav_nav__3Bp-Y {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}

.UserHeaderNav_nav__3Bp-Y a,
.UserHeaderNav_nav__3Bp-Y button {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.1s;
  cursor: pointer;
}

.UserHeaderNav_nav__3Bp-Y a:hover,
.UserHeaderNav_nav__3Bp-Y a:focus,
.UserHeaderNav_nav__3Bp-Y button:hover,
.UserHeaderNav_nav__3Bp-Y button:focus {
  background: #fff;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
  outline: none;
}

.UserHeaderNav_nav__3Bp-Y a.active {
  background: #fff;
  box-shadow: 0 0 0 3px #fea;
  border-color: #fb1;
}

.UserHeaderNav_nav__3Bp-Y a.active svg > * {
  fill: #fb1;
}

.UserHeaderNav_mobileButton__LIGYS {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.1s;
  cursor: pointer;
}

.UserHeaderNav_mobileButton__LIGYS::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 2px;
  border-radius: 2px;
  background: currentColor;
  box-shadow: 0 6px currentColor, 0 -6px currentColor;
  transition: 0.2s;
}

.UserHeaderNav_mobileButton__LIGYS:focus,
.UserHeaderNav_mobileButton__LIGYS:hover,
.UserHeaderNav_mobileButtonActive__1eFVW {
  outline: none;
  background: #fff;
  box-shadow: 0 0 0 3px #fea;
  border-color: #fb1;
  color: #fb1;
}

.UserHeaderNav_mobileButtonActive__1eFVW::after {
  transform: rotate(90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}

.UserHeaderNav_navMobile__1HCMY {
  display: block;
  position: absolute;
  top: 70px;
  right: 0px;
  padding: 0 1rem;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 0;
  pointer-events: none;
}

.UserHeaderNav_navMobileActive__SvRot {
  transition: 0.3s;
  transform: none;
  transform: initial;
  opacity: 1;
  pointer-events: initial;
  z-index: 100;
}

.UserHeaderNav_navMobile__1HCMY a,
.UserHeaderNav_navMobile__1HCMY button {
  display: flex;
  align-items: center;
  background: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  padding: 0.5rem 0;
  cursor: pointer;
}

.UserHeaderNav_navMobile__1HCMY a:hover svg > *,
.UserHeaderNav_navMobile__1HCMY button:hover svg > * {
  fill: #fb1;
}

.UserHeaderNav_navMobile__1HCMY button {
  border-bottom: none;
}

.UserHeaderNav_navMobile__1HCMY svg {
  margin-right: 0.5rem;
}

.UserHeader_header__LrslC {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  position: relative;
}

.UserPhotoPost_photoPost__17JfR {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 40rem) {
  .UserPhotoPost_photoPost__17JfR {
    grid-template-columns: 1fr;
  }
}

.UserPhotoPost_file__1dV0G {
  margin-bottom: 1rem;
}

.UserPhotoPost_preview__1c1eA {
  border-radius: 1rem;
  background-size: cover;
  background-position: center center;
}

.UserPhotoPost_preview__1c1eA::after {
  content: "";
  display: block;
  height: 0px;
  padding-bottom: 100%;
}

