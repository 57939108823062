.footer {
  background: #fb1;
  padding: 1rem;
  height: 5rem;
  text-align: center;
  color: #764701;
}

.footer p {
  margin-top: 0.5rem;
}
